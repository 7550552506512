import Button from "@components/Button";
import { LayoutContext } from "@lib/context/layout/LayoutContext";
import { useSessionContext } from "@lib/context/session";
import {
	deleteFollowRequest,
	getMyBeatportQuery,
	postFollowRequest,
} from "@lib/network/my";
import { EVENT_NAMES, pushFollowEvent } from "@lib/utils/dataLayer";
import { Track } from "@models/track";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import { CollectionControls } from "../CollectionControls";
import {
	ActionsWrapper,
	Breadcrumb,
	ImageWrapper,
	Item,
	Title,
	TitleWrapper,
	Wrapper,
} from "./HeadingWithBreadcrumb.style";

enum ALLOWED_VIEW_TYPES {
	GENRE = "genre",
	ARTIST = "artist",
	LABEL = "label",
	CHARTS = "charts",
	MY_BEATPORT = "my-beatport",
	RECOMMENDED_TRACKS = "recommended-tracks",
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ALLOWED_VIEW_TYPE_VALUES = Object.values(ALLOWED_VIEW_TYPES);

const ALLOWED_FOLLOW_TYPES = [
	ALLOWED_VIEW_TYPES.ARTIST,
	ALLOWED_VIEW_TYPES.LABEL,
];

interface Props {
	id: number;
	slug: string;
	title: string;
	viewType: typeof ALLOWED_VIEW_TYPE_VALUES[number];
	location?: string;

	image?: string;
	tracks?: Track[];
	showPlay?: boolean;
	showAddToQueue?: boolean;
	showAddToPlaylist?: boolean;
	showSocial?: boolean;
	showFollow?: boolean;
	showTabs?: boolean;
}

const Header: React.FC<Props> = ({
	id,
	slug,
	title,
	viewType,
	location,
	image,
	showPlay,
	showAddToQueue,
	showAddToPlaylist,
	showSocial,
	showFollow,
	tracks = [],
	showTabs = true,
}) => {
	const { getAccessToken, getIsAnon, getIsSessionValid } = useSessionContext();
	const accessToken = getAccessToken();
	const isLoggedInSessionValid = getIsSessionValid({ isAnonAllowed: false });
	const isAnon = getIsAnon();
	const { t } = useTranslation("translation");
	const router = useRouter();
	const { authenticateLink } = useContext(LayoutContext);
	const [isFollowing, setIsFollowing] = useState(false);
	const showControls =
		showPlay || showAddToQueue || showAddToPlaylist || showSocial;

	const baseLink =
		viewType === ALLOWED_VIEW_TYPES.CHARTS ?
			"/charts" :
			slug !== "" ?
				`/${viewType}/${slug}/${id}` :
				id !== 0 ?
					`/${viewType}/${id}` :
					`/${viewType}`;
	const currentPath =
		router.asPath.indexOf("?") > -1 ?
			router.asPath.split("?")[0] :
			router.asPath;

	const { data: myBeatport, refetch } = useQuery(
		{ ...getMyBeatportQuery(accessToken || "", !isAnon), enabled: isLoggedInSessionValid },
	);

	useEffect(() => {
		const f =
			myBeatport?.results?.performer?.includes(id) ||
			false ||
			myBeatport?.results?.label?.includes(id) ||
			false;
		setIsFollowing(f);
	}, [id, myBeatport]);

	const followUnfollow = async (follow: boolean) => {
		if (!ALLOWED_FOLLOW_TYPES.includes(viewType)) {
			return;
		}
		setIsFollowing(follow);
		let response;
		if (follow) {
			response = await postFollowRequest({ id, type: viewType, accessToken });
		} else {
			response = await deleteFollowRequest({
				id,
				type: viewType,
				accessToken,
			});
		}

		if (response?.success) {
			refetch();
			pushFollowEvent({
				id: id.toString(),
				type: viewType,
				event: follow ? EVENT_NAMES.FOLLOW : EVENT_NAMES.UNFOLLOW,
				name: title,
			});
		}
	};

	const updateFollow = (follow: boolean) => {
		if (isAnon) {
			authenticateLink("", () => {
				followUnfollow(follow).then(() => { });
			});
		} else {
			followUnfollow(follow).then(() => { });
		}
	};

	const renderFollow = () => {
		return isFollowing ?
				(
					<Button type="primary" onClick={() => updateFollow(false)}>
						{t("Following")}
					</Button>
				) :
				(
					<Button type="outline" onClick={() => updateFollow(true)}>
						{t("Follow")}
					</Button>
				);
	};

	const newDjChart = () => {
		if (isAnon) {
			authenticateLink("/dj/charts/new");
		} else {
			router.push("/dj/charts/new");
		}
	};

	return (
		<ImageWrapper>
			{image && image != "" && (
				<Image src={image} alt={title} width={87} height={87} />
			)}
			<Wrapper>
				<TitleWrapper>
					<Title>{title}</Title>
					<ActionsWrapper>
						{showFollow && renderFollow()}
						{showControls && (
							<CollectionControls
								location={location}
								showPlay={showPlay}
								showAddToQueue={showAddToQueue}
								showAddToPlaylist={showAddToPlaylist}
								tracks={tracks}
								showSocial={showSocial}
							/>
						)}
					</ActionsWrapper>
				</TitleWrapper>
				{showTabs && (
					<Breadcrumb>
						{viewType !== ALLOWED_VIEW_TYPES.MY_BEATPORT && (
							<Item className={currentPath === baseLink ? "active" : undefined}>
								<Link href={baseLink} prefetch={false} title={title}>
									{t("Featured")}
								</Link>
							</Item>
						)}
						{viewType !== ALLOWED_VIEW_TYPES.CHARTS && (
							<Item
								className={
									currentPath.endsWith("/tracks") ||
									currentPath.endsWith("/my-beatport") ?
										"active" :
										undefined
								}
							>
								<Link
									href={
										currentPath.includes("/my-beatport") ?
											`${baseLink}` :
											`${baseLink}/tracks`
									}
									prefetch={false}
									title={`${title} tracks`}
								>
									{t("Tracks")}
								</Link>
							</Item>
						)}

						{viewType !== ALLOWED_VIEW_TYPES.CHARTS &&
							viewType !== ALLOWED_VIEW_TYPES.MY_BEATPORT && (
							<Item
								className={
									currentPath.endsWith("/releases") ? "active" : undefined
								}
							>
								<Link
									href={`${baseLink}/releases`}
									prefetch={false}
									title={`${title} Releases`}
								>
									{t("Releases")}
								</Link>
							</Item>
						)}

						{viewType === ALLOWED_VIEW_TYPES.MY_BEATPORT && (
							<>
								<Item
									className={
										currentPath.endsWith("/artists") ? "active" : undefined
									}
								>
									<Link
										href={`${baseLink}/artists`}
										prefetch={false}
										title={`${title} Artists`}
									>
										{t("Artists")}
									</Link>
								</Item>
								<Item
									className={
										currentPath.endsWith("/labels") ? "active" : undefined
									}
								>
									<Link
										href={`${baseLink}/labels`}
										prefetch={false}
										title={`${title} Labels`}
									>
										{t("Label.Labels")}
									</Link>
								</Item>
							</>
						)}

						{viewType !== ALLOWED_VIEW_TYPES.LABEL &&
							viewType !== ALLOWED_VIEW_TYPES.CHARTS &&
							viewType !== ALLOWED_VIEW_TYPES.MY_BEATPORT && (
							<Item
								className={
									currentPath.endsWith("/charts") ? "active" : undefined
								}
							>
								<Link
									href={`${baseLink}/charts`}
									prefetch={false}
									title={`${title} charts`}
								>
									{t("Charts.label")}
								</Link>
							</Item>
						)}

						{viewType === ALLOWED_VIEW_TYPES.CHARTS && (
							<Item
								className={
									currentPath.endsWith("/charts/all") ? "active" : undefined
								}
							>
								<Link
									href={`${baseLink}/all`}
									prefetch={false}
									title={`${title}`}
								>
									{t("AllDJCharts")}
								</Link>
							</Item>
						)}
						{viewType === ALLOWED_VIEW_TYPES.CHARTS && (
							<Button type="primary" onClick={() => newDjChart()}>
								{t("NewChart")}
							</Button>
						)}
					</Breadcrumb>
				)}
			</Wrapper>
		</ImageWrapper>
	);
};

export { ALLOWED_VIEW_TYPES };
export default Header;
